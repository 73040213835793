
.basic{
  display: inline-block;
  background-image: url("/assets/images/success.svg");
  background-repeat: no-repeat;
  width: 23px;
  height: 23px;

  &.AirportShuttle{
    background-image: url("/assets/images/shuttle.svg")  ;
  }
  &.FreeAirportShuttle{
    background-image: url("/assets/images/shuttle.svg")  ;
  }
  &.FreeBreakfast{
    background-image: url("/assets/images/breakfast.svg")  ;
  }
  &.FreeParking{
    background-image: url("/assets/images/parking.svg")  ;
  }
  &.Streetparking{
    background-image: url("/assets/images/parking.svg")  ;
  }
  &.Securedparking{
    background-image: url("/assets/images/parking.svg")  ;
  }
  &.PetsAllowed{
    background-image: url("/assets/images/pets.svg")  ;
  }
  &.Restaurant{
    background-image: url("/assets/images/restaurant.svg")  ;
  }
  &.SwimmingPool{
    background-image: url("/assets/images/pool.svg")  ;
  }
  &.IndoorSwimmingPool{
    background-image: url("/assets/images/pool.svg")  ;
  }
  &.FitnessCenterorSpa{
    background-image: url("/assets/images/spa.svg")  ;
  }
  &.Fitness{
    background-image: url("/assets/images/fitness.svg")  ;
  }
  &.NoSmokingRoomsFacilities{
    background-image: url("/assets/images/smoking.svg")  ;
  }
  &.Non-smokingthroughout{
    background-image: url("/assets/images/smoking.svg")  ;
  }
  &.BusinessCenter{
    background-image: url("/assets/images/business.svg")  ;
  }
  &.Wifi{
    background-image: url("/assets/images/wifi.svg")  ;
  }
  &.FreeInternet{
    background-image: url("/assets/images/wifi.svg")  ;
  }
  &.WiFiavailableinallareas{
    background-image: url("/assets/images/wifi.svg")  ;
  }
  &.WiFieverywhere{
    background-image: url("/assets/images/wifi.svg")  ;
  }
  &.FreeInternetAccess{
    background-image: url("/assets/images/wifi.svg")  ;
  }
  &.Accessible{
    background-image: url("/assets/images/accessible.svg")  ;
  }
  &.Bedding{
    background-image: url("/assets/images/bed.svg");
  }
  &.Inclusive{
    background-image: url("/assets/images/inclusive.svg");
  }
  &.Luggagestorage{
    background-image: url("/assets/images/luggage.svg");
  }
}
