@import "../../scss/variables";

.travel-button{
  min-height: 44px;
  background: $primary-color;
  border: 1px solid $primary-color;
  font-size:1.7rem;
  text-align: center;
  border-radius: 0;
  height: 44px;
  color: #ffffff;
  padding:  9px 20px 11px 20px;
  &.min-width{
    min-width: 133px;
  }


  &.travel-secondary{
    background: $primary-white;
    border: 1px solid #333;
    color: #333;
    &:hover, &:active, &:focus{
      background: rgba(51, 51, 51, 0.1) !important;
      box-shadow: none;
      color: #333;
      border: 1px solid #333 !important;
    }

  }

  &:hover{
    color: white;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  &:focus, &:hover{
   color: white;
    background: $primary-hover;
  }
  &:active{
    background-color: $primary-active !important;
    border-color: $primary-active !important;
  }
  &:disabled {
    opacity: 0.3;
    background-color: transparent;
    border:1px solid #333333;
    color:  #333333;
  }
  &.small{
    width: 150px;
  }
  &.large{
    width: 250px;
  }

  .spinner-border,.button-text{
    margin: auto;
    //vertical-align: middle;
    position: relative;
    right: 0;
    top: 0;
  }
}

.travel-tertiary{
  background-color:#fff;
  border-color: $very-dark-grey;
  border-radius: 0;
  width: auto;
  padding: 5px;
  font-size: 1.4rem;

  &:hover{
    background-color: #f1f3f4;
  }
  &.selected{
    background-color:#E1E2E0;
  }

}
//default button focus
.btn:focus{
  outline: -webkit-focus-ring-color auto 1px;
  box-shadow: none;
}
.btn-link{
  color: $very-dark-grey;
  &:hover{
    color: $very-dark-grey;
  }
}

@media only screen and (max-width: 575px){
  form travel-button button.travel-button{
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }

  .travel-button{
    &.travel-secondary{
      &.min-width{
        width: 100% !important;
      }
    }
  }
}
