/* FONT FACE: UniversNext */
@font-face {
  font-family: 'univers-next-bold';
  src: url('/assets/fonts/UniversNext-bold.woff2') format('woff2'),
  url('/assets/fonts/UniversNext-bold.ttf') format('truetype'),
  url('/assets/fonts/UniversNext-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'univers-next-light';
  src: url('/assets/fonts/UniversNext-light.woff2') format('woff2'),
  url('/assets/fonts/UniversNext-light.ttf') format('truetype'),
  url('/assets/fonts/UniversNext-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'univers-next-medium';
  src: url('/assets/fonts/UniversNext-medium.woff2') format('woff2'),
  url('/assets/fonts/UniversNext-medium.ttf') format('truetype'),
  url('/assets/fonts/UniversNext-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'univers-next';
  src: url('/assets/fonts/UniversNext-regular.woff2') format('woff2'),
  url('/assets/fonts/UniversNext-regular.ttf') format('truetype'),
  url('/assets/fonts/UniversNext-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'univers-next-thin';
  src: url('/assets/fonts/UniversNext-thin.woff2') format('woff2'),
  url('/assets/fonts/UniversNext-thin.ttf') format('truetype'),
  url('/assets/fonts/UniversNext-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* set base values */
  --text-base-size: 1.6rem;
  --text-scale-factor: 1.188;
  --text-scale-ratio: 1.21;

  // desktop view ration
  @include media-breakpoint-up(md) {
    // you can manage typography editing only these 2 variables
    --text-scale-factor: 1.375;
    --text-scale-ratio: 1.26;
  }

  /* type scale */
  /** e.g.
  * lg  = base * factor * ratio
  * xl  = base * factor * ratio * ratio
  * xxl = base * factor * ratio * ratio * ratio
  * ...
  **/
  --text-xxs: calc(var(--text-base-size) * 0.625);
  --text-xs: calc(var(--text-base-size) * 0.75);
  --text-sm: calc(var(--text-base-size) * 0.875);
  --text-sm-md: calc(var(--text-base-size) * 1.125);
  --text-md: calc(var(--text-base-size) * var(--text-scale-factor));
  --text-md-lg: calc(var(--text-base-size) * 1.5);
  --text-lg: calc(
          var(--text-base-size) * var(--text-scale-factor) * var(--text-scale-ratio)
  );
  --text-xl: calc(
          var(--text-base-size) * var(--text-scale-factor) * var(--text-scale-ratio) *
          var(--text-scale-ratio)
  );
  --text-xxl: calc(
          var(--text-base-size) * var(--text-scale-factor) * var(--text-scale-ratio) *
          var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxxl: calc(
          var(--text-base-size) * var(--text-scale-factor) * var(--text-scale-ratio) *
          var(--text-scale-ratio) * var(--text-scale-ratio) *
          var(--text-scale-ratio)
  );

  --text-xxxxl: calc(
          var(--text-base-size) * var(--text-scale-factor) * var(--text-scale-ratio) *
          var(--text-scale-ratio) * var(--text-scale-ratio) *
          var(--text-scale-ratio) * var(--text-scale-ratio)
  );
}

/* text size */
.text--xxxxl {
  font-size: var(--text-xxxxl);
}

.text--xxxl {
  font-size: var(--text-xxxl);
}

h1,
.text--xxl {
  font-size: var(--text-xxl);
}

h2,
.text--xl {
  font-size: var(--text-xl);
}

h3,
.text--lg {
  font-size: var(--text-lg);
}

.text--md-lg {
  font-size: var(--text-md-lg);
}

h4,
.text--md {
  font-size: var(--text-md);
}

.text--sm-md {
  font-size: var(--text-sm-md);
}

.text--base {
  font-size: var(--text-base-size);
}

.text--sm,
small {
  font-size: var(--text-sm);
}

.text--xs {
  font-size: var(--text-xs);
}

.text--xxs {
  font-size: var(--text-xxs);
}

p {
  line-height: var(--body-line-height);
}

.light {
  font-family: 'univers-next-light', sans-serif;
}

.regular {
  font-family: 'univers-next', sans-serif;
}

.medium {
  font-family: 'univers-next-medium', sans-serif;
}

.bold {
  font-family: 'univers-next-bold', sans-serif;
}
