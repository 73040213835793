@import "scss/custom";
@import "bootstrap/scss/bootstrap";
@import "scss/variables";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "scss/typography";
@import "bootstrap-select/sass/bootstrap-select";
@import "ol/ol";
@import "directives/travel-button/travel.button";
@import "scss/hotel";

/*Override the default browser focus color */
:focus {
  outline: -webkit-focus-ring-color auto 1px;
  box-shadow: none;
}
/*Remove outline when the mouse is used */
body.using-mouse :focus {
  outline: none !important;
  & + label:before{
    outline: none !important;
  }
  box-shadow: none;
}

html {
  position: relative;
  height: 100%;
  font-size: 62.5%;
}
body {
  color: $very-dark-grey;
  height: 100%;
  font-family: $font-family;
  font-size: 1.6rem;
  background-color: $background;
  > .container {
    padding: 60px 15px 0;
  }
}
.main-wrapper{
  min-height: 100%;
  position: relative;
  .main-page{
    padding-top: 150px;
    &.notification-padding{
      padding-top: 246px;
    }
    &.is-admin{
      padding-top:180px;
    }
    width: 100%;
    min-height: 100vh;
    position: relative;
  }

}
*[role=link]{
  cursor: pointer;
}
a{
  font-weight: 400;
  font-size: 1.4rem;
  color: $very-dark-grey;
  cursor: pointer;
  text-decoration: none;
  &:hover{
    color: $very-dark-grey;
    //border-bottom: 2px solid $secondary-black;
    //padding-bottom: 2px;
    text-decoration: none !important;
  }
  &.no-bottom:hover{
    border-bottom: none;
    padding-bottom: 0px;
    text-decoration: none !important;
  }
}

.trademark{
  font-size: 6px !important;
  top: -12px;
  position: relative;

}

// Hide MAP contributors
.ol-attribution.ol-uncollapsible{
  display: none;
}





//b,strong{
//  font-weight: 400;
//  font-family: 'heebo-bold';
//}
span[role=button]{
  //font-weight: bold;
  font-size: 1.4rem;
  color: $very-dark-grey;
  cursor: pointer;
  text-decoration: none;
  &.primary-black{
    color: $primary-black;
  }
  &:hover{
    color: $very-dark-grey;
    //border-bottom: 2px solid $primary-blue-dark;
    //padding-bottom: 2px;
    text-decoration: none !important;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: $primary-blue;
}
.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}
.myshadow{
  -webkit-box-shadow: 0px 0px 5px 0px rgba(211,211,211,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(211,211,211,1);
  box-shadow: 0px 0px 5px 0px rgba(211,211,211,1);
  border-radius: 6px;
}

.btn-items-expanded-compressed{
  &.collapsed{
    img{
      transform: rotate(180deg);
      transition: .3s transform ease-in-out;
    }
  }
}

.filters{
  background-color: transparent;
  &.close-filter{
    text-align: right;
    font-size: 2.6rem;
    position: fixed;
    z-index: 3;
    right: 10px;
    background: #ffffff;
    width: 100%;
    top: 107px;

    .close-filter-button{
      font-size: 2.6rem;
    }
  }

  .close-filter-bottom{
    border-color: black;
    border-radius: 0;
    height: 40px;
    margin-top: 15px;
  }

  .slider-value{
    justify-content: space-between;
    margin-top: 2.4rem;
    //font-weight: bold;
    white-space: nowrap;
    .slider-pts-wrapper{
      border: 1px solid #767676;
      padding: 9px 3px;
    }
    input{
      text-align: center;
      background-color: white;
      border: none;

      //padding: 5px;
      color: $very-dark-grey;
      //font-weight: bold;
      max-width: 9.8rem;
      //&:disabled{
      //  background-color: white !important;
      //  border: 1px solid lightgrey;
      //}
    }
    span{
      margin: 0 10px;
      //font-weight: bold;
      //font-size: 1.4rem;
    }
    .payment-span{
      font-weight: normal;
      margin-right: 0px;
    }

  }
  .ngx-slider .ngx-slider-bubble{
    visibility: hidden!important;
  }
  ul{
    padding: 0;
    list-style-type: none;
  }
  .card{
    background-color: transparent;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid $primary-light-grey;
    color: $primary-black;
    padding-bottom: 10px;
    .card-header{
      padding: 0;
      background-color: transparent;
      border-bottom: none;
      &+ div{
        padding-bottom: 20px;
      }
      button{
        font-family: 'univers-next-medium', sans-serif;
        padding-left: 0px;
        color: $very-dark-grey;
        font-size: 1.4rem;
        span{
          font-size: 1.8rem;
        }
        &.collapsed{
          +.arrow .fa-chevron-up{
            transform: rotate(180deg);
            transition: .3s transform ease-in-out;
          }
        }
      }
      .arrow{
        font-size: 1.2rem;
        color: $primary-blue;
        font-weight: bold;
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }
      }

    }
  }
  .card-body{
    padding: 0px;
    margin: 5px 0px;

  }
}

.logged-in-as-banner {
  background: #000;
  color: #fff;
  height: 3rem;
  font-family: heebo-medium, sans-serif;
  font-size: 1.4rem;
}

.agent-fees-container {
  margin-top: 15px;

  &.hidden {
    display: none;
  }

  .no-fees-container {
    width: 100%;
  }
}

.agent-fees-payment-container {
  margin-top: 45px;

  &.hidden {
    display: none;
  }
}

.sort-by-text {
  margin-top: 0.5rem;
}

.filters-button-box {
  display: flow-root;
  padding-left: 8px;
}

.filters-button-view {
  display: inline-flex;
  width: auto;
  padding: 3px 10px 5px 10px;
  //border-color: black;
  //border-radius: 3px;
  //background-color: #F9CA6F;
  margin: -2px 10px 5px 0;
  //font-size: 14px;

  border-width: 1px;
  border-style: solid;
  background-color:#fff;
  border-color: $very-dark-grey;
  border-radius: 0;
  //width: auto;
  //padding: 5px;
  font-size: 1.4rem;

  &.no-border {
    border-style: none;
  }

  &:hover{
    background-color: #f1f3f4;
  }

  .close-mobile-view-filter-button {
    display: inline-block;
    padding-left: 10px;
    &:hover{
      cursor: pointer;
    }
  }
}

.sort-wrapper{
  &.hotel{
    margin-top: 13px;
    margin-bottom: 25px;
  }
  ul{
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      margin-right:10px;

      button{
        background-color:#fff;
        color: $very-dark-grey;
        border-color: $very-dark-grey;
        width: auto;
        border-radius: 0px;
        font-size: 1.4rem;
        min-width: 160px;
        &.btn{
          padding: 0.6rem 1.2rem
        }
        span{
          float:left;
        }
        span:last-child{
          float: right;
        }
        span i{
          //float: right;
          padding: 0 2px 0 2px;
          font-size: 1.2rem;
        }

        &.selected{
          background-color: #E1E2E0;
        }

      }

    }
  }
}
#travellers{
  border: none;
  padding-top: 12px;
  padding-bottom: 0px;
  padding-left: 0px;
  border-bottom: 2px solid #929ba9;
  border-radius: 0;
  background-image: url('/assets/images/icons/baby-chevy-down-blue.svg');
  background-repeat: no-repeat;
  background-position: right 8px top 15px;
  background-size: 16px 16px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  label{
    z-index: 2;
    font-size: 1.3rem;
    color: $primary-blue;
    position: absolute;
    top: -8px;
    right: 0px;
    left: 0px;
    pointer-events: none;
    height: 42px;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
  }
}


.book-box {
  margin-top: 30px;
  .thank-you{
    margin-bottom: 50px;
  }
  &.box-flight{
    //margin-top: 50px;
  }
  span.align-middle {
    width: 36px;
    //margin: 0 20px;
  }

  .book-summary-info {
    &.summary-flight{
      //margin-top: 120px;
      &.success{
        margin-top: 70px;
      }
    }
    //margin-top: 130px;
    span.float-left {
      font-size: 1.8rem;
      margin-bottom: 22px;
    }

    .print-button {
      cursor: pointer;
    }
  }

  .booking-data-info {
    .recap-data {
      margin-bottom: 4px;
      //font-weight: bold;
      font-size: 1.4rem;
      .value {
        //color: $primary-black;
        //&.SUCCESS,&.ACCEPTED, &.CONFIRMED{
        //  color: #28a745!important;
        //}
        //&.PENDING{
        //  color: #ffc107!important;
        //}
        //&.FAILURE,&.CANCELLED{
        //  color: #dc3545!important;
        //}

      }

      .detail {
        min-width: 160px;
      }
    }
  }
}

.small-nav{
  .col{
    border-bottom: 1px solid #ccc;
    &:not(.active-page) {
      opacity: 0.5;
    }
  }
}

/*=== MAIN SEARCH ===*/

.main-container-search{

  margin-top: 50px;
  margin-bottom:15px;
  background-color: $primary-white;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 3px;
  min-height: 500px;
  align-items: center;
  justify-content: center;

  .search-form-wrapper{
    padding: 0px;
  }

  h1,h3{
    //color: $secondary-black;
    margin: 25px 0px;
  }
  .radio-inline{
    padding-right: 20px;

  }
  travel-button{
    button{
      width: 94px;
    }
  }
  .input-typeahead{
    padding-right: 60px;
  }
}

/*===================*/

/****************** + and - buttons **************/
.passenger-btn-container{
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  &.btn-action{
    border: 1px solid $very-dark-grey;
    &:hover{
      background-color: #f1f3f4;
      cursor: pointer;
    }
  }

}
/*************************************************/
//.close-icon{
//  display: block;
//  position: absolute;
//  top: 40px;
//  right: 70px;
//
//}
//INPUT & SELECT (FLOATING LABEL)
input,select{
  &:disabled{
    cursor: not-allowed;
  }
}
select  {
  //background-image: url('../images/icons/chevron-down-black.svg') !important;
  //cursor: pointer;
  //background-repeat: no-repeat;
  //background-position: right 14px top 16px;
  //background-size: 18px 14px;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image:
          url('/assets/images/icons/chevron-down-black.svg'),
          linear-gradient(to right, #C9D0D5, #C9D0D5);
  background-position:
          calc(100% - 12px) calc(1em + -1px),
          calc(100% - 2.5em) 0.3em;
  background-size:
          16px 16px,
          1px 2em;
  background-repeat: no-repeat;


}

.spinner-border{
  position: absolute;
  top: 45px;
  right: 70px;
  &.back{
    top: 5px!important;
    left: 178px!important;
  }
  &.car{
    top: 5px !important;
    left: 150px !important;
  }
}

.spinner-border-sm{
  border-width: 0.1em;
}
.invalid-feedback{
  display: block !important;
  background-image: url("/assets/images/icons/StatusErrorOnLight.svg");
  background-repeat: no-repeat;
  padding-left: 25px;
  color: $very-dark-grey;
}
.dropdown-menu.show{
  margin-top: -1px;
  margin-bottom: -1px;
}
.inputTravel,
.selectTravel {
  font-size: 1.6rem;
  //font-weight: bold;
  padding: 10px;
  display: block;
  width: 100%;
  height: 44px;
  color: $very-dark-grey;
  background-color: transparent;
  border: 1px solid $dark-grey;
  border-radius: 0;
  &.on-search{
    width: 179px;
  }
  //border-bottom: 2px solid $primary-grey;
  &.is-invalid{
    border-color: red !important;
    background-color: #F9F2F3;
  }
  &:disabled{
    ~label{
      color: $primary-grey !important;
    }
  }
  &.hsbc-datepicker{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image:
            url('/assets/images/icons/CalendarH.svg');
    //linear-gradient(to right, #C9D0D5, #C9D0D5);
    &.open{
      background-image:
              url('/assets/images/icons/CalendarActive.svg');
      //linear-gradient(to right, #C9D0D5, #C9D0D5);
    }
    background-position:
            calc(100% - 12px) calc(1em + -1px),
            calc(100% - 2.5em) 0.3em;
    background-size:
            16px 16px,
            1px 2em;
    background-repeat: no-repeat;


  }

  &.typeahead{

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image:
            url('/assets/images/icons/chevron-down-black.svg'),
            linear-gradient(to right, #C9D0D5, #C9D0D5);
    &.open{
      background-image:
              url('/assets/images/icons/chevron-up-black.svg'),
              linear-gradient(to right, #C9D0D5, #C9D0D5);
      &~.close-icon{
        display: block;
      }
    }
    &~.close-icon{
      display: none;
      position: absolute;
      top: 40px;
      right: 70px;

    }

    background-position:
            calc(100% - 12px) calc(1em + -1px),
            calc(100% - 2.5em) 0.3em;
    background-size:
            16px 16px,
            1px 2em;
    background-repeat: no-repeat;
    &+.dropdown-menu{
      border-radius: 0;
      padding-top: 0;
      padding-bottom: 0;

      .dropdown-item{
        color: $very-dark-grey;
        height: 43px;
        .ngb-highlight{
          text-decoration: underline;
        }
        &:hover{
          background-color: #E4E8EA;
          cursor: pointer;
        }
        &.active{
          border-left: 3px solid $primary-color;
        }
      }

      button{
        &:nth-child(even) {background: #f1f3f4}
        &:nth-child(odd) {background: #fff}
      }
    }
  }

  &.minimum-width {
    min-width: 230px;
  }
}

::placeholder {
  color: #6E6E6E;
  opacity: 1; //Only for Firefox
}

.is-invalid{
  //background-image:none !important;
}
.arrow-right{
  width: 9px!important;
}

//PHONE FIELD
.phone-prefix-wrapper{
  .input-group{
    flex-wrap: nowrap;
    .input-group-prepend{
      margin-bottom: 8px;
      margin-top: 8px;
      margin-right: -2px;
    }
  }
  .floating-label.move-up{
    position: initial;
    .inputTravel~label{
      top:-10px
    }
  }

  .inputTravel:focus~label{
    top: -10px;
    font-size: 1.3rem;
  }
  .inputTravel:not(:placeholder-shown)~label{
    top:-10px;
    font-size: 1.3rem;
  }
  .bootstrap-select .selectpicker.is-focused + button.dropdown-toggle{
    border-bottom: 2px solid $primary-blue
  }
  .bootstrap-select .selectpicker.is-invalid + button.dropdown-toggle{
    border-bottom: 2px solid red
  }

  .bootstrap-select > select.mobile-device:focus + .dropdown-toggle, .bootstrap-select .dropdown-toggle:focus{
    //
  }

  .dropdown.bootstrap-select{
    width: auto !important;
    padding: 0;
    border: none;
    .dropdown-toggle{
      height: 30px;
      &:after{
        display: none;
      }
      &:focus{
        outline: $primary-blue auto 1px !important;
      }
      border: none;
      padding-top: 0;
      padding-right: 20px;
      border-bottom: 2px solid $primary-grey;
      padding-bottom: 0;
      background-color: #fff;
      background-image: url(/assets/images/icons/baby-chevy-down-blue.svg) !important;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: right 0px top 5px;
      background-size: 16px 16px;
    }
    button .country-option{
      display: none;
    }
  }

}

.back-btn-wrap{

  img{
    transform: rotate(180deg);
  }
  span{
    cursor:pointer;
    font-size: 14px;
  }
}
//RADIO
.input-wrap.input-radio {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0;

  input {
    position: absolute;
    display: inline-block;
    left: -5px;
    opacity: 0;
    margin-top: -4px;
    height: 32px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:checked {
      opacity: 0;
    }

    //&.selected-flight{
    //  [type="radio"]:checked + label:before,
    //  [type="radio"]:not(:checked) + label:before {
    //    content: '';
    //    position: absolute;
    //    right: 0;
    //    top: 0;
    //    width: 32px;
    //    height: 32px;
    //    border: 1px solid $dark-grey;
    //    border-radius: 100%;
    //    background: #fff;
    //  }
    //}
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    line-height: 28px;
    display: inline-block;
    color: $very-dark-grey;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border: 1px solid $dark-grey;
    border-radius: 100%;
    background: #fff;
  }



  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background: $teal;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  label {
    position: relative;
    top: -10px;
    color: $very-dark-grey;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
  input:focus+label::before{
    outline: -webkit-focus-ring-color auto 1px;
  }

  //label {
  //  position: relative;
  //  top: -10px;
  //  margin-left: 12px;
  //  color: $primary-black;
  //  //font-family: 'heebo';
  //  font-size: 1.6rem;
  //  font-weight: 400;
  //  letter-spacing: 0.15px;
  //  line-height: 24px;
  //
  //  &::before {
  //    content: "";
  //    position: relative;
  //    top: 8px;
  //    left: -12px;
  //    display: inline-block;
  //    width: 32px;
  //    height: 32px;
  //    border: 2px solid $primary-grey;
  //    border-radius: 24px;
  //
  //    box-sizing: border-box;
  //    transition: background-color ease-in-out 250ms;
  //    transition: border ease-in-out 250ms;
  //  }
  //
  //  &:hover {
  //    cursor: pointer;
  //  }
  //}

  // Checked
  //input:checked+label::before {
  //  background-color: $teal;
  //    //border: 6px solid $primary-white /*!important*/;
  //}
  //
  //// Focus
  //input:focus+label::before {
  //  background-color: transparent;
  //  border: 2px solid $primary-grey /*!important*/;
  //  //box-shadow: 0 0 4px 3px rgba(115, 195, 235, 0.65);
  //}
  //// Focus while checked
  //input[type="radio"]:checked:focus+label::before {
  //  background-color: $teal;
  //  //width: 16px;
  //  //height: 16px;
  //  //-webkit-transition: all 0.2s ease;
  //  //transition: all 0.2s ease;
  //  border: 2px solid $primary-grey /*!important*/;
  //  //border: 6px solid $primary-white /*!important*/;
  //
  //}
}
//TYPEAHEAD
@media only screen and (max-width: 575px){
  ngb-typeahead-window{
    width: 100%;
    button.dropdown-item{
      white-space: normal;
    }
  }
}

ngb-typeahead-window{
  &.dropdown-menu{
    max-height: 565px;
    overflow-y: auto;
  }
}

.ngx-slider-ceil{
  opacity: 1 !important;
}

//CHECKBOX
.input-wrap.input-checkbox {
  align-items: center;
  padding-left: 40px;
  padding-top: 0;
  position: relative;


  .filter-description{
    margin-left: auto;
    //margin-right: 10px;
    font-size: 1.3rem;
    //font-weight: bold;
    color: $very-dark-grey;

  }
  label {

    cursor: pointer;
    position: relative;
    top: 0px;
    color: $very-dark-grey;
    //font-family: 'heebo';
    font-size: 1.6rem;
    //font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 30px;
    &.stars{
      line-height: unset;
    }
    span{
      &.my-ellipsis{
        width: 145px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }
  }
  //&.book label{
  //  font-size: 1.3rem;
  //}
  &.filters-check{
    &.disable{
      opacity: 0.5;
    }

    label{
      //text-transform: capitalize;
      //font-weight: bold;
      //appearance: none;
      //-moz-appearance: none;
      //-webkit-appearance: none;
      font-size: 1.3rem;
    }
  }

  input[type="checkbox"] {

    position: absolute;
    top: 26px;
    left: -28px;
    margin: 0px;
    width: 32px;
    height: 32px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid $dark-grey;
    //border-radius: 4px;
    box-sizing: content-box;
    opacity: 0;

    &:first-of-type {
      margin-top: -24px;
    }
    &:checked {
      opacity: 0;
    }

  }

  input[type="checkbox"] + label:before {
    content: '';
    position: absolute;
    top: 0px;
    left: -40px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
    width: 32px;
    height: 32px;
    border: 1px solid $dark-grey;
    //border-radius: 4px;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }

  }

  input[type="checkbox"]:checked + label:before {
    content: '';
    background-color: transparent;
    background-image: url('/assets/images/icons/checkmark-green.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px;
    cursor: pointer;
    //border: 2px solid $primary-blue /*!important*/;
    padding-bottom: 0;
    box-sizing: border-box;
    &:focus + label:before {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  // Focus
  input[type="checkbox"]:focus + label:before {
    outline: -webkit-focus-ring-color auto 1px;
  }
  input[type="checkbox"]:checked:focus + label:before {
    outline: -webkit-focus-ring-color auto 1px;
  }

  // Error

  input[type="checkbox"].has-error + label:before {
    border: 1px solid $primary-red;
  }

  // Disabled
  input[type="checkbox"]:disabled + label:before {
    background-color: $primary-light-grey;
    border: 1px solid $dark-grey /*!important*/;
  }

  // Disabled and checked while disabled
  input[type="checkbox"]:checked:disabled + label:before {
    background-color: $primary-granite;
    background-image: url('/assets/images/icons/checkmark-green.svg');
    border: 2px solid $primary-granite /*!important*/;
  }
}

.input-wrap.input-checkbox input[type="checkbox"]:focus {
  border: 2px solid $primary-blue-dark /*!important*/;
  padding-bottom: 0;
  box-shadow: 0 0 2px 1px rgba(115, 195, 235, 0.65);
}

//PAGINATION

.pagination{
  justify-content: center;
  margin-bottom:20px ;
  .dot-pagination{
    opacity: 1;
    color: #333333;
  }
  //.page-item{
  //  margin-top: 4px;
  //}
  .page-item:first-child .page-link, .page-item:last-child .page-link{
    margin-left: initial;
    padding: 12px 14px;
    border: 1px solid $very-dark-grey;
  }
  .page-item{
    &.active{
      margin-top: 4px;
    }
    margin-top: 4px;
    &:first-child{
      margin-top: 0px;
    }
    &:last-child{
      margin-top: 0px;
    }
  }
  .page-item.active .page-link{
    border-radius:0px;
    width: 33px;
    margin-top: 5px;
    padding: 0.8rem 0;
    text-align: center;
    border: 1px solid $very-dark-grey;
    color: $very-dark-grey;
    background-color: transparent;
    //border:2px solid $primary-blue;
  }

  .page-link{
    margin: 5px;
    background-color: transparent;
    padding: 0.8rem 1.2rem;
    border:none;
    outline: none;
    color: $very-dark-grey;
    a{
      &:focus{
        //outline: $very-dark-grey auto 1px;
      }
    }


  }


  .page-item.disabled .page-link{
    //border: 1px solid #D7D8D6;
    color: #D7D8D6;
    opacity: 0.5;
    background-color: transparent;
  }
  .btn-link{
    color:$very-dark-grey;
    border: 1px solid $very-dark-grey;

    &:hover , &:focus {
      border-color: #323334;
      color:#323334;
      text-decoration: none;
    }
  }


}
@media only screen and (max-width: 575px){

  .pagination{
    width: 100%;
    .page-item:first-child{
      margin-right: auto;
    }
    .page-item:last-child{
      margin-left: auto;
    }
  }
}


//ALERTS
.alert{
  border-radius: 0;
  .close{
    opacity: 1;
  }
  .icon-wrapper{
    background-image: url('/assets/images/icons/StatusErrorOnLight.svg');
    background-size: 24px 24px;
    background-position: 0px 0;
    background-repeat: no-repeat;
    text-align: center;
    min-width: 24px;
    min-height: 24px;
    //margin-top: 20px;
  }
  .custom-close{
    width: 24px;
    height: 24px;
    display: block;
    background-image: url('/assets/images/icons/close-granite.svg');
  }
  &.alert-danger{
    background-color: #F9F1F3;
    color: $very-dark-grey;
    border: 1px solid #E5B2B5;
    //border-bottom: 2px solid #C81414;
  }
}
.bb-1{
  padding-bottom: 20px;
  border-bottom:1px solid #333333;
  &.additional-opt{
    padding-bottom: 10px;
  }
}


.bundle-heading-section{
  border-left: 3px solid $primary-color;
  line-height: 36px;
  padding-left: 10px;
  //height: 41px;
}
.header-after-book{
  border-bottom: 1px solid;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid black;
}

//SPACING
.padding-8{padding:8px;}
.padding-16{padding:16px;}
.padding-19{padding:19px;}

//FONT SIZE
.font-13{
  font-size: 1.3rem !important;
}
.font-10{
  font-size: 1rem !important;
}
.font-30{
  font-size:3rem !important;
}
.font-m{
  font-size: 1.8rem !important;
}
.font-s{
  font-size: 1.4rem !important;
}
.font-16{
  font-size: 1.6rem !important;
}
.font-xs{
  font-size: 1.2rem !important;
}
.font-xxl{
  font-size: 3rem !important;
}
.font-l{
  font-size: 2.2rem !important;
}
.font-20{
  font-size: 2rem!important;
}
.font-xl{
  font-size: 2.7rem !important;
}
.font-35{
  font-size: 3.5rem !important;
}
.font-xxxl{
  font-size: 3.6rem ;
}
.font-8{
  font-size: 0.8rem;
}
.font-weight-bold{
  font-weight: bold;
}
.information.info-title,.information.info-title span{
  font-size: 3.5rem;
  margin-bottom: 0;
}
.information-i{
  vertical-align: text-top;
  width: 18px !important;
}
@media only screen and (max-width: 575px){
  .information.info-title, .information.info-title span{
    font-size: 3rem;
  }
}

#book-button-wrapper {

  .confirmation-text{
    display: block;
    margin-top: -10px;
  }

  button {
    margin-top: 20px;
    min-width: 160px;
  }
}


//BUTTONS
.button-wrapper {
  //padding: 15px;
  //text-align: center;
}

//MODAL
.modal,.modal-open{
  padding-right: 0px !important;
  .modal-content{
    //border-top: 6px solid $primary-blue;
  }
}


//SLIDER
.custom-slider{

  //DOUBLE ARROW
  .double-arrow{
    .ngx-slider .ngx-slider-pointer:after{
      top: 0px !important;
      background-color: transparent !important;
    }

    .ngx-slider-pointer.ngx-slider-pointer-min{
      &:before{
        width: 50% !important;
      }
      &:after{
        content: '\f054';
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: 900;
        font-size: 1.1rem;
        width: 50%;
        line-height: 24px;
        position: absolute;
        text-align: center;
        color: white;
        display: block!important;
      }
    }
  }



  .ngx-slider {

    .ngx-slider-model-value{
      display: none;
    }

    .ngx-slider-bar{
      height: 8px;
    }
    .ngx-slider-pointer, .ngx-slider-pointer.ngx-slider-active{
      top: -12px;
      background-color: white !important;
      border: 1px solid #767676;
      width: 32px;
      height: 32px;
      &.ngx-slider-pointer-min{
        &:before{
          //content: '\f053';
          font-family: 'Font Awesome 5 Free', sans-serif;
          font-weight: 900;
          font-size: 1.1rem;
          width: 100%;
          line-height: 32px;
          position: absolute;
          text-align: center;
          color: white;
        }
        &:after{
          display: none;
        }
      }
      &.ngx-slider-pointer-max{
        &:before{
          //content: '\f054';
          font-family: 'Font Awesome 5 Free', sans-serif;
          font-weight: 900;
          font-size: 1.1rem;
          width: 100%;
          line-height: 32px;
          position: absolute;
          text-align: center;
          color: white;
        }
        &:after{
          display: none;
        }
      }

    }
    .ngx-slider-selection{
      background: $teal;
    }
  }
}

.custom-slider-hotel{
  .ngx-slider {
    .ngx-slider-model-value{
      //display: none;
    }
    .ngx-slider .ngx-slider-pointer{
      background-color: $primary-blue;
      &:after{
        background: transparent;
      }
    }
    .ngx-slider-pointer, .ngx-slider-pointer.ngx-slider-active{
      top: -10px;
      background-color: $primary-blue !important;
      width: 24px;
      height: 24px;
      &:before{
        content: '\f053';
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-size: 1.1rem;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 4px;
        left: 3px;
        color: $primary-blue;
      }
      &:after,&:hover:after{
        content: '\f054';
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-size: 1.1rem;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 4px;
        left: 14px;
        color: $primary-blue;
        background: $primary-blue;
      }
    }
    .ngx-slider-selection{
      background: $primary-blue;
    }
  }
}
//ALERT
.alert-wrapper{
  position: fixed;
  top: 70px;
  right: 15px;
  max-width: 400px;
  z-index: 1000;
  .alert{
    margin-right: 1.6rem;
  }
}
hr{
  border-top: 1px solid $very-dark-grey;
  margin-top: 2rem;
  margin-bottom: 2rem;
  &.light{
    border-top: 1px solid #D9DCE1;
  }
}

//UTILS
.v-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
#passoport-modal {

  span{
    display: block;
    li{
      margin-top: 15px;
      list-style: none;
      strong{
        display: initial;
      }

    }
    strong{
      display: block;
      margin-top: 15px;
    }
  }


}

.justify-end{
  display: flex;
  justify-content: flex-end;
}

//ALERTS CANCELLATION
.alert-notification-cancellation{
  &.success{
    background: rgba(0, 132, 127, 0.1);
    border: 1px solid #00a69d;
  }
  &.error{
    background-color: #F9F1F3;
    color: $very-dark-grey;
    border: 1px solid #E5B2B5;
  }

  display: block;
  padding: 12px 8px;
  box-sizing: border-box;
  position: relative;
  &.notify{
    background-color: rgba(255,200,39,.1);
    border-bottom: 2px solid #FFC827;

  }
  .alert-icon{
    width: 56px;
    text-align: center;
    padding: 0px 16px;
  }
  .alert-msg-wrap{
    p{
      font-size: 1.4rem;
    }
    .msg-title{
      font-weight: bold;
    }
  }
}

.alert-notification {
  background: #EBEFF4 0% 0% no-repeat padding-box;
  border: 1px solid #ACBDCE;
  opacity: 1;
  //border-bottom: 2px solid #0075be;
  display: block;
  padding: 12px 8px;
  box-sizing: border-box;
  position: relative;

  &.notify {
    background: #FFF8EA 0% 0% no-repeat padding-box;
    border: 1px solid #FFCF70;

    .alert-icon {
      padding: 0px 16px;

    }
  }
  &.error {
    background-color: #fff5f5;
    border: 1px solid #dc3545;

    .alert-icon {
      padding: 0px 16px;

    }
  }

  .alert-icon {
    width: 56px;
    text-align: center;
    padding: 0px 16px;

  }

  .alert-msg-wrap {
    span {
      font-size: 1.6rem;
    }

    .msg-title {
      //font-weight: bold;
    }
  }
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    } 100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      }
}


/* DATEPICKER */

ngb-datepicker.dropdown-menu{
  //-webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.08);
  //-moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.08);
  //box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.08);
  border: 1px solid #767676;
  //-webkit-border-radius: 6px;
  //-moz-border-radius: 6px;
  //border-radius: 6px;
  //font-weight: bold;


  .bg-primary {
    background-color: $teal !important;
    //border-radius: 4px;
    border: 1px solid $teal;
    color: white !important;
  }
  .ngb-dp-navigation-chevron {
    border-width: 0.1em .1em 0 0;
    color:$very-dark-grey;
    width: 11px;
    height: 11px;
  }
  ngb-datepicker-navigation{
    background-color: #fff;
    padding: 0 10px;
    //border-bottom:2px solid #F5F5F5;
    height: 40px;
  }
  ngb-datepicker-navigation-select>.custom-select{
    font-size: 1.4rem;
    border:none;
    background-color: transparent;
    &:focus{
      //outline: $primary-blue auto 1px;
    }
  }
  .ngb-dp-month:first-child .ngb-dp-week{
    &:not(:last-child){
      border-bottom: 1px solid #EDEDED;
    }
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: #fff!important;
  }
  .ngb-dp-weekday.small{
    //font-weight: 600;
    //text-transform: uppercase;
  }
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday{
    font-style: normal;
    width: 3.5rem;
    height: 2.8rem;
    color:black;
  }

  .disabled .text-muted.outside{
    color: #33334D !important;
  }
  .text-muted.outside{
    color: $very-dark-grey !important;
  }

  .ngb-dp-month-name{
    font-size:1.6rem;
    color: $very-dark-grey;
  }

  .ngb-dp-months{
    padding: 10px;
  }
  .ngb-dp-day{
    font-size: 1.2rem;
    &:focus{
      //outline: $primary-blue auto 1px;
    }
    .btn-light{
      color: black;
      &:focus{
        background-color: transparent;
        //color: $primary-blue;
      }
      &:hover{
        background-color: transparent;
        border: 1px solid black;
        border-radius: 0px;
        //color: $primary-blue;
      }
      &:visited{
        background-color: transparent;
        //color: $primary-blue;
      }

    }
  }
  [ngbDatepickerDayView] {
    margin: 0 auto;
    width: 2.8rem;
    height: 2.8rem;
    line-height: 2.8rem;
  }
}

//MEDIA QUERY
//Side Filters
@media only screen and (max-width: 991px){

  .mobile-app .filter-side{
    top: 0px !important;
  }

  .filter-side{
    z-index: 2;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    transition: 1s;
    background-color: $primary-white;
    position: absolute;
    width: 100%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0px;
    top: 106px !important;
    transform: none !important;
    padding-top: 30px;

    .filters{
      border-radius: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px)
{
  .filter-side
  {
    top: 174px!important;
  }

  .filters.close-filter {
    top: 152px;
  }
}

//PRINT STUFF
@media print {
  //general
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    print-color-adjust: exact !important;                 /*Firefox*/
  }
  #travel-header,#step-component-wrapper,footer{
    display: none;
    height:0px !important;
  }
  #book-contract-total{
    display: block !important;
  }
  #collapse-price, .collapse:not(.show), .d-none{
    display: block !important;
  }
  body{
    background-color: #fff;
  }
  //hotel
  #print-hotel-page, #print-flight-page, #print-car-page, .main-page{
    padding-top: 0 !important;
  }


}



/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px)
{
  .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up,
  .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }
  //.page-item:last-child .page-link{
  //  right: 0px;
  //  z-index: 100;
  //  position: absolute;
  //}
  //.page-item:first-child .page-link{
  //  left: 7px;
  //  z-index: 100;
  //  position: absolute;
  //}

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
  .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up,
  .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
  .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
  .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
  .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
  {
    display: none !important;
  }
}

//Date format on label
.format-on-label {
  margin-top: -30px;

  span {
    font-size: 14px;
  }
}

@media (max-width: 576px)
{
  .format-on-label {
    margin-top: 0;
  }
}
.blink{
  animation: c1 1s linear infinite alternate
}
@keyframes c1 {to{opacity: 0}}
