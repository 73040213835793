// Your variable overrides
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

//PRIMARY COLOURS
$primary-color:#DB0011;
$primary-hover: #af000d;
$primary-active: #83000a;
$secondary: #4d6474;
$secondary-hover: #3e505d;
$secondary-active: #1d262c;
$very-dark-grey: #333333;
$dark-grey: #767676;
$light-grey: #d7d8d6;
$outline-active: #c9d0d5;
$teal: #00847f;
$footer-bg-primary: #253038;
$footer-divider: #4f575c;
$footer-bg-secondary: $secondary-active;
//OLD-STUFF
$primary-blue: #0075BE;
$primary-blue-dark: #005587;
$primary-cerulean: #FFFFFF;
$primary-black: #001928;
$primary-red: #D50032;
$secondary-black: #323334;
$primary-granite: #646C76;
$primary-grey: #929BA9;
$primary-light-grey: #D9DCE1;
$primary-white: #FFFFFF;
$green-icon:#008A00; //#00AA00; -> Changed for accessibility
$red-text:#D60000;
//BACKGROUND
$background: #ffffff;
//FONT
$font-family: 'univers-next';

.teal{
  color: $teal !important;
}
.text-color{
  color: $very-dark-grey !important;
}

.primary-black{
 color: $primary-black !important;
}
.primary-granite{
  color: $primary-granite;
}
.primary-grey{
  color: $primary-grey;
}
.primary-light-grey{
  color: $primary-light-grey;
}
.green-icon{
  color: $green-icon !important;
}

.primary-blue{
  color:$primary-blue;
}
.red-text{
  color: $red-text;
}
